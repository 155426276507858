<template>
  <div
    :class="className"
    :style="{height:height,width:width}"
  />
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons') // echarts theme
// import { debounce } from '@/utils'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    chartData: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '90%'
    },
    height: {
      type: String,
      default: '24.1vh'
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }

  },
  mounted () {
    this.initChart()
    // this.__resizeHandler = debounce(() => {
    //   if (this.chart) {
    //     this.chart.resize()
    //   }
    // }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart (val) {
      this.chart = echarts.init(this.$el)
      this.setOptions(this.chartData)
    },
    setOptions ({ expectedData } = {}) {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },

        grid: {
          left: 30,
          right: 30,
          bottom: 20,
          top: 40,
          containLabel: true
        },
        xAxis: {
          data: this.chartData.timeData,
          // data: [1, 2, 3, 4, 5, 6, 7],
          boundaryGap: true,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#A8B1AF'
          }
        },
        yAxis: {
          type: 'value',
          name: '订单数',
          axisLabel: {
            color: '#A8B1AF'
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(48, 98, 104,0.2)',
              width: 1
            }
          }
        },
        series: [
          {
            name: '充电订单',
            type: 'bar',
            // data: [100, 200, 160, 300, 400, 120, 130],
            label: {
              show: true,
              position: 'top',
              valueAnimation: true,
              color: '#fff'
            },
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(9, 252, 192, 1)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(5, 126, 96, 1)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            data: this.chartData.orderNumData
          }
        ]
      })
    }
  }
}
</script>
